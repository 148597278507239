<template>
  <div class="ma-auto" style="max-width: 600px">
    <ApLoading v-if="isResetting" text="Clearing Configurations..." />
    <DevFeaturesCard v-else />
  </div>
</template>

<script>
import DevFeaturesCard from '@/components/dev/DevFeaturesCard'
import ApLoading from '@/components/common/ApLoading'

export default {
  components: {
    ApLoading,
    DevFeaturesCard,
  },
  data() {
    return {
      isResetting: false,
    }
  },
  watch: {
    '$route.query.reset': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.isResetting = true
          const query = { ...this.$route.query }

          delete query.reset
          this.$router.replace({ query })
          window.location.reload()
        }
      },
    },
  },
}
</script>
